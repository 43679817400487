<template>
  <v-card :outlined="outlined">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("open ticket") }}</v-toolbar-title>
    </v-toolbar>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-card-text>
        <validation-provider :name="$t('subject')" rules="max:250" v-slot="{ errors, valid, dirty, classes }">
          <v-text-field maxlength="250" :label="$t('subject')" v-model="subject" :error-messages="errors"
            :success="valid && dirty" :class="classes" counter="250" :clearable="!isMobile" />
        </validation-provider>
        <validation-provider :name="$t('issue')" rules="required|max:1024" v-slot="{ errors, valid, dirty, classes }">
          <v-textarea v-model="message" :label="$t('issue')" :error-messages="errors" :success="valid && dirty"
            :class="classes" counter="1024" :clearable="!isMobile" />
        </validation-provider>
        <email-list-input :label="$t('email contact')" v-model="email" max-length="1024" multiple />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="
          $emit('canceled');
        reset();
        " text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" :disabled="invalid" @click="createTicket" :loading="loading" text>
          {{ $t("create ticket") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import EmailListInput from "@/components/basics/EmailListInput";
import isMobile from "@/utils/mixins/isMobile";
import showErrors from "@/utils/mixins/showErrors";

export default {
  name: "SupportTicket",
  mixins: [isMobile, showErrors],
  components: {
    EmailListInput,
  },
  props: {
    productId: {
      type: Number,
      required: false,
      default: null,
    },
    customerId: {
      type: Number,
      required: false,
      default: null,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    subject: null,
    message: null,
    email: null,
    loading: false,
  }),
  computed: {
    url() {
      if (this.productId != null)
        return "products/" + this.productId + "/ticket";
      else return "customers/" + this.customerId + "/ticket";
    },
  },
  watch: {
    email: {
      immediate: true,
      handler(value) {
        if (value == null) this.email = this.$store.getters.deliveryEmail;
      },
    },
  },
  methods: {
    createTicket() {
      var that = this;
      this.loading = true;
      this.$http
        .post(this.url, {
          email: this.email,
          subject: this.subject,
          message: this.message,
        })
        .then((response) => {
          that.$snotify.success(that.$i18n.t("ticket created"));
          that.$emit("created", response.data);
          that.reset();
        })
        .catch((error) => {
          if (that.$refs.obs != null) {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          } else {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    reset() {
      this.email = null;
      this.subject = null;
      this.message = null;
      if (this.$refs.obs != null) this.$refs.obs.reset();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "open ticket": "Open ticket",
    "subject": "Subject",
    "issue": "Issue",
    "email contact": "Email contact",
    "cancel": "Cancel",
    "create ticket": "Create ticket",
    "ticket created": "ticket has been successfully created"
  },
  "de": {
    "open ticket": "Ticket öffnen",
    "subject": "Betreff",
    "issue": "Nachricht",
    "email contact": "Email Kontakt",
    "cancel": "Abbruch",
    "create ticket": "Ticket anlegen",
    "ticket created": "ticket wurde erforlgreich angelegt"
  }
}
</i18n>
