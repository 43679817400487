<template>
  <v-card outlined class="ma-4" :loading="loading">
    <validation-observer ref="obs" v-slot="{ errors, invalid, valid }">
      <v-card-title>
        {{ $t("terminate product") }}
      </v-card-title>
      <v-card-text>
        <v-row v-if="showReason">
          <v-col cols="12">
            <validation-provider
              vid="comment"
              :name="$t('termination reason')"
              rules="max:500"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-textarea
                name="comment"
                :label="$t('termination reason')"
                v-model="terminationReason"
                :error-messages="errors"
                :success="valid && dirty"
                :class="classes"
                counter="500"
                :clearable="!isMobile"
                rows="1"
                auto-grow
              />
            </validation-provider>
          </v-col>
        </v-row>
        <template v-if="showDate">
          <template v-if="isDomain">
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="wishdate"
                  :name="$t('termination date')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="terminationDate"
                    name="wishdate"
                    :label="$t('termination date')"
                    :items="domainTerminationDates"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                    :success="valid && dirty"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-alert text type="info">
                  {{ $t("domainTermInfo") }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="terminationDate == null && !loadingDomain" no-gutters>
              <v-col cols="12">
                <v-alert type="error" outlined v-if="showDomainConfirmMsg">
                  <v-row align="center">
                    <v-col class="grow">
                      <div
                        v-html="
                          $t('domainTermAlert', {
                            date: domainTerminationDates[0].text,
                          })
                        "
                      />
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        @click="showDomainConfirmMsg = false"
                        color="error"
                        text
                      >
                        <v-icon left> mdi-checkbox-marked-circle </v-icon>
                        {{ $t("agree") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12">
                <date-picker
                  v-model="terminationDate"
                  name="wishdate"
                  :label="$t('termination date')"
                  :allowedDates="allowedDates"
                />
              </v-col>
            </v-row>
          </template>
        </template>
        <v-row v-if="showEmail">
          <v-col cols="12">
            <email-list-input
              name="email"
              :label="$t('email contact')"
              v-model="terminationEmail"
              :max-length="multipleEmail ? 1024 : 64"
              :multiple="multipleEmail"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="terminateProduct()"
          :disabled="
            loading ||
            domainShopError ||
            invalid ||
            (isDomain && terminationDate == null && showDomainConfirmMsg)
          "
          text
        >
          {{ $t("terminate") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import DatePicker from "@/components/basics/DatePicker";
import isMobile from "@/utils/mixins/isMobile";
import formatDate from "@/utils/mixins/formatDate";
import EmailListInput from "@/components/basics/EmailListInput";

export default {
  name: "ProductTermination",
  mixins: [isMobile, formatDate],
  components: {
    DatePicker,
    EmailListInput,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    product: null,
    domain: null,
    terminationReason: null,
    terminationDate: null,
    terminationEmail: null,
    terminationNote: null,
    loadingTermination: false,
    loadingDomain: false,
    domainShopError: false,
    showDomainConfirmMsg: true,
  }),
  computed: {
    isDomain() {
      return this.product && this.product.tags.includes("domain-reg");
    },
    loading() {
      return this.loadingTermination || this.loadingDomain;
    },
    showReason() {
      return !this.isDomain;
    },
    showNote() {
      return this.$store.getters.isStaff;
    },
    showDate() {
      return true;
    },
    showEmail() {
      return !this.isDomain;
    },
    multipleEmail() {
      return true;
    },
    domainTerminationDates() {
      var that = this;
      var nextContractEndDate = new Date();
      var cancellationOptions = [];
      if (this.domain && this.domain.cancellation_options.length > 0) {
        cancellationOptions = this.domain.cancellation_options
          .map(function (item) {
            return {
              text: that.formatDate(item.contract_end_date),
              value: item.contract_end_date,
              latest_termination_date: item.latest_termination_date,
              disabled: that
                .$moment(item.latest_termination_date)
                .isBefore(new Date()),
            };
          })
          .sort(function (a, b) {
            return that
              .$moment(a.latest_termination_date)
              .isAfter(b.latest_termination_date)
              ? 1
              : -1;
          });
        nextContractEndDate = cancellationOptions[0].value;
      }
      cancellationOptions.push({
        text: this.$i18n.t("domainTermMsg", {
          date: this.formatDate(nextContractEndDate),
        }),
        value: null,
        latest_termination_date: null,
        disabled: false,
      });
      return cancellationOptions;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) this.initialize();
      },
    },
    domainTerminationDates(items) {
      var that = this;
      items.forEach(function (item) {
        if (that.terminationDate == null && !item.disabled)
          that.terminationDate = item.value;
      });
    },
    terminationDate(value) {
      if (value != null) this.showDomainConfirmMsg = true;
    },
  },
  methods: {
    initialize() {
      this.product = { ...this.value };
      this.domainShopError = false;
      this.showDomainConfirmMsg = true;
      if (this.product.tags.includes("domain-reg")) this.getDomain();
    },
    allowedDates(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(date).isSame(today, "day") ||
        this.$moment(date).isAfter(today)
      );
    },
    terminateProduct() {
      var that = this;
      this.loadingTermination = true;
      this.$http
        .delete("products/" + this.product.id, {
          data: {
            wishdate: this.terminationDate || null,
            comment: this.terminationReason || null,
            email: this.TerminationEmail || null,
            note: this.terminationNote || null,
          },
        })
        .then((response) => {
          that.$snotify.success(that.$i18n.t("termination confirmed"));
          that.$emit("terminated");
          that.$router.push({
            name: "tasks",
            params: { selectedTaskId: response.data.id },
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          that.loadingTermination = false;
        });
    },
    getDomain() {
      var that = this;
      this.loadingDomain = true;
      this.$http
        .get("services/dns/registry", {
          params: { product_id: this.product.id },
        })
        .then((response) => {
          that.domainShopError = response.data.length != 1;
          if (response.data.length == 1) {
            that.$http
              .get("services/dns/registry/" + response.data[0].id)
              .then((response) => {
                that.domain = response.data;
              })
              .catch((err) => {
                that.$store.commit("setSystemError", {
                  msg: err.message,
                  title: err.title,
                });
              })
              .finally(() => {
                that.loadingDomain = false;
              });
          } else if (response.data.length == 0) {
            that.loadingDomain = false;
            that.$store.commit("setSystemError", {
              msg: that.$i18n.t("domainShopErrorNotFound"),
              title: that.$i18n.t("domainShopErrorTitle"),
            });
          } else {
            that.loadingDomain = false;
            that.$store.commit("setSystemError", {
              msg: that.$i18n.t("domainShopErrorMultiple"),
              title: that.$i18n.t("domainShopErrorTitle"),
            });
          }
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "immediately": "immediately",
    "terminate product": "Terminate Product",
    "email contact": "Email contact",
    "termination reason": "Termination reason",
    "terminate": "Terminate",
    "termination date": "Termination date",
    "domainShopErrorNotFound": "could not find a domain for this product",
    "domainShopErrorMultiple": "found more that one domains for this product",
    "domainShopErrorTitle": "DomainShop Error",
    "termination confirmed": "termination confirmed",
    "confirmed": "confirmed",
    "domainTermMsg": "Delete domain immediately, termination on {date}",
    "domainTermAlert": "The domain will be deleted immediately, but you will still have to pay until the next termination date {date}.<br/>Restoring a deleted domain is subject to a fee and success is not guaranteed.",
    "domainTermInfo": "The termination date depends on the registrar service and may differ from the billing date.",
    "agree": "agree"
  },
  "de": {
    "immediately": "sofort",
    "terminate product": "Produkt kündigen",
    "email contact": "Email Kontakt",
    "termination reason": "Kündigungsgrund",
    "terminate": "Kündigen",
    "termination date": "Kündigungsdatum",
    "domainShopErrorNotFound": "konnte keine Domain für dieses Produkt finden",
    "domainShopErrorMultiple": "mehr als eine Domain für dieses Produkt gefunden",
    "domainShopErrorTitle": "DomainShop Fehler",
    "termination confirmed": "Kündigung bestätigt",
    "confirmed": "bestätigt",
    "domainTermMsg": "Domain sofort löschen, Kündigung auf den {date}",
    "domainTermAlert": "Die Domain wird sofort gelöscht, sie muss aber trotzdem bis zum nächsten Kündigungsdatum {date} bezahlt werden.<br/>Die Wiederherstellung einer gelöschten Domain ist kostenpflichtig und ein Erfolg ist nicht garantiert.",
    "domainTermInfo": "Das Kündigungsdatum ist vom Registrar-Dienst abhängig und kann vom Verrechnungsdatum abweichen.",
    "agree": "bestätigen"
  }
}
</i18n>
