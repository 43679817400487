<template>
  <v-card class="pb-2" :loading="loading">
    <v-toolbar color="titleBar" dark flat dense v-if="product">
      <v-toolbar-title>{{ product.product_type_name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip
        top
        v-if="
          $store.state.session.partnerType === 'WHO' &&
          product != null &&
          (product.tags.includes('access') || product.tags.includes('voip'))
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="dialogDeactivate || $store.getters.isReadOnly"
            @click="
              dialogDeactivate = !dialogDeactivate;
              dialogDelete = false;
              dialogSupport = false;
            "
            v-on="on"
          >
            <v-icon>
              {{ product.blocked ? "mdi-power-plug" : "mdi-power-plug-off" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ product.blocked ? $t("activate") : $t("deactivate") }}</span>
      </v-tooltip>

      <v-tooltip
        top
        v-if="
          $store.state.session.partnerType === 'WHO' ||
          (product != null && product.tags.includes('domain-reg'))
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="
              dialogDelete ||
              workersWithoutSupportTickets.length > 0 ||
              (product != null && product.billing_end_date != null) ||
              $store.getters.isReadOnly
            "
            @click="
              dialogDelete = !dialogDelete;
              dialogSupport = false;
              dialogDeactivate = false;
            "
            v-on="on"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("terminate") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="dialogSupport || $store.getters.isReadOnly"
            large
            @click="
              dialogSupport = !dialogSupport;
              dialogDelete = false;
              dialogDeactivate = false;
            "
            v-on="on"
          >
            <v-icon>mdi-face-agent</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open ticket") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="loading"
            :loading="loading"
            @click="loadProduct()"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <wiki slug="product-details" />
    </v-toolbar>

    <v-expand-transition>
      <v-card v-if="dialogDeactivate" outlined class="ma-4">
        <v-card-title>
          {{ blocked ? $t("activate") : $t("deactivate") }}
        </v-card-title>
        <v-card-text v-if="product != null && product.tags.includes('access')">
          {{ $t("activateDeactivateAccess") }}
        </v-card-text>
        <v-card-text
          v-else-if="product != null && product.tags.includes('voip')"
        >
          {{ $t("activateDeactivatePhone") }}
        </v-card-text>
        <v-card-text v-else>
          {{ $t("activateDeactivateElse") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDeactivate = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            v-if="blocked"
            color="primary"
            @click="
              dialogDeactivate = false;
              deactivateProduct();
            "
            text
          >
            {{ $t("activate") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            @click="
              dialogDeactivate = false;
              activateProduct();
            "
            text
          >
            {{ $t("deactivate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>

    <v-expand-transition>
      <product-termination
        v-if="dialogDelete"
        v-model="product"
        class="ma-4"
        @cancel="dialogDelete = false"
        @terminated="
          dialogDelete = false;
          loadProduct();
        "
      />
    </v-expand-transition>

    <v-expand-transition>
      <support-ticket
        v-if="dialogSupport && product != null"
        :product-id="product.id"
        class="ma-4"
        @canceled="dialogSupport = false"
        @created="
          dialogSupport = false;
          loadProduct();
        "
        outlined
      />
    </v-expand-transition>

    <v-card v-if="!loading" outlined class="ma-4">
      <v-simple-table dense class="pb-2 mt-4" v-if="product">
        <template v-slot:default>
          <tbody>
            <tr>
              <th>{{ $t("state") }}</th>
              <td>
                <product-status :product="product" />
              </td>
            </tr>
            <tr>
              <th>{{ $t("product name") }}</th>
              <td>
                {{ product.product_name ? product.product_name : "-" }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("create date") }}</th>
              <td class="monospace text-no-wrap">
                {{ product.created ? formatDateTime(product.created) : "-" }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("billing period") }}</th>
              <td>
                <v-chip class="mt-2 mb-2" outlined>{{
                  $t(product.billing_period)
                }}</v-chip>
              </td>
            </tr>
            <tr>
              <th>{{ $t("first billing date") }}</th>
              <td class="monospace text-no-wrap">
                {{
                  product.billing_start_date
                    ? formatDate(product.billing_start_date)
                    : "-"
                }}
              </td>
            </tr>
            <tr v-if="showNextBillingDate">
              <th>{{ $t("next billing date") }}</th>
              <td class="monospace text-no-wrap">
                {{
                  getBillingNextDate(product)
                    ? formatDate(getBillingNextDate(product))
                    : "-"
                }}
              </td>
            </tr>
            <tr v-if="product.billing_end_date">
              <th>{{ $t("last billing date") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatDate(product.billing_end_date) }}
              </td>
            </tr>
            <tr style="cursor: pointer">
              <th @click="dialogDescription = true">
                <v-icon small>mdi-pencil-outline</v-icon>
                {{ $t("description") }}
              </th>

              <v-menu
                v-model="dialogDescription"
                :close-on-content-click="false"
                min-width="30%"
                max-width="50%"
              >
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ textDescription ? textDescription : "-" }}
                  </td>
                </template>
                <validation-observer v-slot="{ errors, invalid, valid }">
                  <v-card>
                    <v-card-subtitle>
                      {{ $t("description text") }}:
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <validation-provider
                        vid="row"
                        :name="$t('row')"
                        rules="max:100"
                        v-slot="{ errors, valid, dirty, classes }"
                      >
                        <v-text-field
                          v-model="textDescription"
                          autofocus
                          single-line
                          counter="100"
                          :error-messages="errors"
                          :success="dirty && valid"
                          :class="classes"
                          :clearable="!isMobile"
                          outlined
                          full-width
                        />
                      </validation-provider>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="
                          textDescription = product.description;
                          dialogDescription = false;
                        "
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        :disabled="invalid"
                        @click="
                          saveDescription();
                          dialogDescription = false;
                        "
                        text
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </validation-observer>
              </v-menu>
            </tr>

            <tr style="cursor: pointer">
              <th @click="dialogRow = true">
                <v-icon small>mdi-pencil-outline</v-icon>
                {{ $t("row") }}
              </th>
              <v-menu
                v-model="dialogRow"
                :close-on-content-click="false"
                min-width="20%"
                max-width="30%"
              >
                <template v-slot:activator="{ on }">
                  <td v-on="on">{{ textRow ? textRow : "-" }}</td>
                </template>
                <validation-observer v-slot="{ errors, invalid, valid }">
                  <v-card>
                    <v-card-subtitle> {{ $t("row text") }}: </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <validation-provider
                        vid="row"
                        :name="$t('row')"
                        rules="numeric"
                        v-slot="{ errors, valid, dirty, classes }"
                      >
                        <v-text-field
                          v-model="textRow"
                          name="row"
                          type="number"
                          :error-messages="errors"
                          :success="dirty && valid"
                          :class="classes"
                          autofocus
                          single-line
                          :clearable="!isMobile"
                          outlined
                          full-width
                        />
                      </validation-provider>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="
                          textRow = product.row;
                          dialogRow = false;
                        "
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        :disabled="invalid"
                        @click="
                          saveRow();
                          dialogRow = false;
                        "
                        text
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </validation-observer>
              </v-menu>
            </tr>

            <tr v-if="product && product.commission_code != null">
              <th>{{ $t("commission code") }}</th>
              <td>{{ product.commission_code }}</td>
            </tr>
            <tr v-if="product && product.commission_descr != null">
              <th>{{ $t("commission descr") }}</th>
              <td>{{ product.commission_descr }}</td>
            </tr>

            <tr>
              <th>{{ $t("services") }}</th>
              <td>
                <product-tags v-if="product" :product="product" />
              </td>
            </tr>

            <tr v-if="product && product.belongs != null">
              <th>{{ $t("belongs to product") }}</th>
              <td>
                <v-chip
                  :to="{
                    name: 'product-details',
                    params: { productId: product.belongs.id },
                  }"
                  color="primary"
                  link
                  class="my-1 mr-1"
                >
                  {{ product.belongs.product_name }}
                </v-chip>
              </td>
            </tr>

            <tr v-if="product && product.associated.length > 0">
              <th>{{ $t("associated products") }}</th>
              <td>
                <v-chip
                  v-for="(p, i) in product.associated"
                  :key="i"
                  :to="{
                    name: 'product-details',
                    params: { productId: p.id },
                  }"
                  color="primary"
                  link
                  class="my-1 mr-1"
                >
                  {{ p.product_name }}
                </v-chip>
              </td>
            </tr>

            <tr v-if="workers.length > 0">
              <th>{{ $t("open tasks") }}</th>
              <td>
                <v-tooltip top color="info">
                  <template v-slot:activator="{ on, attrs }">
                    <v-row
                      v-for="(value, i) in workers"
                      :key="i"
                      class="my-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-chip
                        :to="{
                          name: 'task-details',
                          params: { taskId: value.id },
                        }"
                        color="secondary"
                        link
                      >
                        {{ value.subject }}
                      </v-chip>
                    </v-row>
                  </template>
                  <span>{{ $t("openWorkerMsg") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card v-if="!loading" outlined class="ma-4">
      <product-prices v-if="product" :product="product" :open-attr="openAttr" />
    </v-card>
  </v-card>
</template>

<script>
import ProductPrices from "@/components/products/ProductPrices";
import ProductTermination from "@/components/products/ProductTermination";
import EmailListInput from "@/components/basics/EmailListInput";
import ProductTags from "@/components/products/ProductTags";
import ProductStatus from "@/components/products/ProductStatus";
import ProductTools from "@/utils/mixins/productTools";
import SupportTicket from "@/components/products/SupportTicket";
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "ProductView",
  mixins: [formatDate, formatDateTime, ProductTools, isMobile],
  components: {
    ProductPrices,
    EmailListInput,
    ProductTags,
    ProductStatus,
    ProductTermination,
    SupportTicket,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
    openAttr: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      deleteDate: this.$moment(new Date()).format("YYYY-MM-DD"),
      email: this.$store.getters.email,
      deleteDescription: "",
      datePickerMenu: false,
      dialogSupport: false,
      dialogDelete: false,
      dialogDeactivate: false,
      dialogDescription: false,
      textDescription: "",
      dialogRow: false,
      textRow: "",
      blocked: true,
      product: null,
      loading: false,
      workers: [],
    };
  },
  computed: {
    workersWithoutSupportTickets() {
      return this.workers.filter(function (worker) {
        return worker.worker_code != "workerticketsupport";
      });
    },
  },
  watch: {
    "$store.state.session": function () {
      this.email = this.$store.getters.email;
    },
    productId: {
      immediate: true,
      handler() {
        this.loadProduct();
      },
    },
  },
  methods: {
    showNextBillingDate() {
      return (
        this.product != null &&
        this.product.billing_next_date != null &&
        (this.product.billing_end_date == null ||
          this.$moment(this.product.billing_end_date).isAfter(
            this.product.billing_next_date
          ))
      );
    },
    allowedDates(date) {
      return (
        this.$moment().isSame(date, "day") || this.$moment().isBefore(date)
      );
    },
    loadProduct() {
      var that = this;
      if (this.productId != null) {
        this.loading = true;
        this.$http
          .get("products/" + this.productId)
          .then((response) => {
            this.product = response.data;
            this.textDescription = response.data.description;
            this.textRow = response.data.row;
            this.blocked = response.data.blocked;
            if (response.data.workers.length > 0) this.loadWorkers();
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    saveDescription() {
      var that = this;
      if (this.textDescription === this.product.description) return;
      this.loading = true;
      this.$http
        .patch("products/" + this.productId, {
          description: this.textDescription,
        })
        .then((response) => {
          this.product = response.data;
          this.textDescription = response.data.description;
          this.textRow = response.data.row;
          this.blocked = response.data.blocked;
          that.$snotify.success(that.$i18n.t("confirm description"));
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveRow() {
      var that = this;
      if (this.textRow === this.product.row) return;
      this.loading = true;
      this.$http
        .patch("products/" + this.productId, {
          row: this.textRow,
        })
        .then((response) => {
          this.product = response.data;
          this.textDescription = response.data.description;
          this.textRow = response.data.row;
          this.blocked = response.data.blocked;
          that.$snotify.success(that.$i18n.t("confirm row"));
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadWorkers() {
      var that = this;
      this.loading = true;
      this.$http
        .get("workers?done=false&aplix_object_id=" + this.productId)
        .then((response) => {
          this.workers = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    activateProduct() {
      var that = this;
      if (this.blocked === true) return;
      this.loading = true;
      this.$http
        .post("products/" + this.productId + "/block")
        .then((response) => {
          that.$snotify.success(that.$i18n.t("confirm deactivate"));
          this.loadProduct();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deactivateProduct() {
      var that = this;
      if (this.blocked === false) return;
      this.loading = true;
      this.$http
        .post("products/" + this.productId + "/unblock")
        .then((response) => {
          that.$snotify.success(that.$i18n.t("confirm activate"));
          this.loadProduct();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "reload": "Reload",
    "open ticket": "Open ticket",
    "subject" : "Subject",
    "issue": "Issue",
    "email contact": "Email contact",
    "terminate": "Terminate",
    "terminate text": "Select a termination date",
    "reason" : "Reasons",
    "submit": "Submit",
    "deactivate": "Deactivate",
    "activate": "Activate",
    "activateDeactivateAccess": "Activate/Deactivate Product for customer billing is continued. Good for not paying customers.",
    "activateDeactivatePhone": "Activate/Deactivate Product for customer billing is continued. Good for not paying customers.",
    "activateDeactivateElse": "Activate/Deactivate Product for customer billing is continued. Good for not paying customers.",
    "confirm activate" : "Activating product",
    "confirm deactivate" : "Deactivating product",
    "confirm terminate" : "Terminating product",
    "allready open ticket" : "There is allready an open ticket",
    "confirm ticket" : "Sending ticket for product",
    "confirm description" : "Saving description",
    "confirm row" : "Saving row",
    "product name": "Product name",
    "state": "State",
    "create date": "Create date",
    "first billing date": "First billing date",
    "next billing date": "Next billing date",
    "last billing date": "Service termination",
    "description": "Description",
    "description text": "Description which is shown under each product on the invoice",
    "save": "Save",
    "row": "Row",
    "row text": "Sets on which row the product should show up",
    "open tasks": "Open tasks",
    "cancel": "Cancel",
    "belongs to product": "Belongs to product",
    "associated products": "Associated products",
    "openWorkerMsg": "Not all product options are available due to ongoing tasks!",
    "billing period": "Billing period",
    "MIV": "monthly",
    "QIV": "quarterly",
    "HIV": "half-yearly",
    "JIV": "yearly",
    "ER": "setup (unique)",
    "EN": "unique",
    "WHO-MIV": "monthly",
    "WHO-QIV": "quarterly",
    "WHO-HIV": "half-yearly",
    "WHO-JIV": "yearly",
    "WHO-ER": "setup (unique)",
    "WHO-EN": "unique",
    "commission code": "Commission code",
    "commission descr": "Commission description"
  },
  "de": {
    "reload": "Neu laden",
    "openTicket": "Ticket eröffnen",
    "subject" : "Betreff",
    "issue": "Anliegen",
    "email contact": "Email Kontakt",
    "terminate": "Kündigen",
    "terminate text": "Kündigungsdatum auswählen",
    "reason" : "Begründung",
    "submit": "Abschicken",
    "deactivate": "Deaktivieren",
    "activate": "Aktivieren",
    "activateDeactivateAccess": "Aktivieren/Deaktivieren des Produkts, die Verrechnung geht aber weiter. Sinnvoll bei nicht zahlenden Kunden.",
    "activateDeactivatePhone": "Aktivieren/Deaktivieren des Produkts, die Verrechnung geht aber weiter. Sinnvoll bei nicht zahlenden Kunden.",
    "activateDeactivateElse": "Aktivieren/Deaktivieren des Produkts, die Verrechnung geht aber weiter. Sinnvoll bei nicht zahlenden Kunden.",
    "confirm activate" : "Produkt wird aktiviert",
    "confirm deactivate" : "Produkt wird deaktiviert",
    "confirm terminate" : "Produkt wird gekündigt",
    "allready open ticket" : "Bereits ein Ticket offen",
    "confirm ticket" : "Ticket für das Produkt gesendet",
    "confirm description" : "Beschreibung wird gespeichert",
    "confirm row" : "Zeile wird gespeichert",
    "product name": "Produkt Bezeichnung",
    "state": "Status",
    "create date": "Erstellt am",
    "first billing date": "Erste Verrechnung",
    "next billing date": "Nächste Verrechnung",
    "last billing date": "Abo Ende",
    "description": "Beschreibung",
    "description text": "Die Beschreibung wird auf der Rechnung unter dem Produkt angezeigt",
    "save": "Speichern",
    "row": "Zeile",
    "row text": "Die Zeile, auf der das Produkt auf der Rechnung erscheinen soll",
    "open tasks": "Laufende Vorgänge",
    "cancel": "Abbrechen",
    "belongs to product": "gehört zu Produkt",
    "associated products": "zugehörige Produkte",
    "openWorkerMsg": "Auf Grund laufender Vorgänge sind nicht alle Produkt Optionen verfügbar!",
    "billing period": "Rechnungsperiode",
    "MIV": "monatlich",
    "QIV": "quartalsweise",
    "HIV": "halbjährlich",
    "JIV": "jährlich",
    "ER": "Einrichtung (einmalig)",
    "EN": "einmalig",
    "WHO-MIV": "monatlich",
    "WHO-QIV": "quartalsweise",
    "WHO-HIV": "halbjährlich",
    "WHO-JIV": "jährlich",
    "WHO-ER": "Einrichtung (einmalig)",
    "WHO-EN": "einmalig",
    "commission code": "Kommission Code",
    "commission descr": "Kommission Beschr."
  }
}
</i18n>
